import React, { Component } from 'react';
import EmploymentCard from './EmploymentCard';

import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBBadge,
  MDBIcon,
  MDBCardTitle
} from "mdbreact";


import './Cv.css';
import sqlbadge from './2014-01.png';
import awscp from './aws-certified-cloud-practitioner.png'
import awscsaa from './aws-certified-solutions-architect-associate.png'

const Cv = () => {

  return (
    <MDBContainer>
      <MDBRow>

        <MDBCol size="3">
          <img className="img-fluid" src={sqlbadge} alt="cert"></img>
        </MDBCol>

        <MDBCol size="6">
          <MDBCard>

            <MDBCardHeader>Paul Mattingly</MDBCardHeader>

            <MDBCardImage src="https://mattinglyuk.s3.eu-west-2.amazonaws.com/IMG_6380_BW.jpg"
              className="rounded img-fluid mx-auto d-block" width="275px"
              alt="Paul Mattingly"></MDBCardImage>

            <MDBCardFooter>

              <MDBBadge className="badgeLink" color="light">
                <a href="https://github.com/pnm563" target="_blank">
                  <MDBIcon fab icon="github" />
                </a>
              </MDBBadge>

              <MDBBadge className="badgeLink" color="light">
                <a href="https://instagram.com/pnm563" target="_blank">
                  <MDBIcon fab icon="instagram" />
                </a>
              </MDBBadge>
              <MDBBadge className="badgeLink" color="light">
                <a href="https://www.linkedin.com/in/paul-mattingly-niu/" target="_blank">
                  <MDBIcon fab icon="linkedin" />
                </a>
              </MDBBadge>

              <MDBBadge className="badgeLink" color="light">
                <a href="mailto:paulus_1st@hotmail.com" target="_blank">
                  <MDBIcon fas icon="envelope" className="fasBig" />
                </a>
              </MDBBadge>

              <MDBBadge className="badgeLink" color="light">
                <a href="https://mattinglyuk.s3.eu-west-2.amazonaws.com/Paul.Mattingly.pdf" target="_blank">
                  <MDBIcon fas icon="file-download" className="fasBig" />
                </a>
              </MDBBadge>


            </MDBCardFooter>

          </MDBCard>
        </MDBCol>
        <MDBCol size="3">
        <a href="https://www.credly.com/badges/93c57efc-a1c0-4373-b80e-92ca70898039/public_url" target="_blank" rel="noreferrer">
          <img className="img-fluid" src={awscp} alt="cert"></img>
          </a>
          <a href="https://www.credly.com/badges/e47992eb-2d23-4610-9839-49f28542a092/public_url" target="_blank" rel="noreferrer">
          <img className="img-fluid" src={awscsaa} alt="cert"></img>
          </a>
        </MDBCol>
      </MDBRow>

      <MDBCard>
        <MDBCardBody>
          <MDBCardText>
            Broadly skilled .NET developer since 2014, backed by 15 years
            IT experience. A genial character with traits of geek who will tackle
            projects and challenges with tenacity and enthusiasm. Finely tuned problem
            solving ability and insatiable appetite for learning.

            Strives to keep momentum while addressing the balance between
            perfection and delivery.

            A dedicated and conscientious individual who has delivered numerous projects
            that have provided measurable benefits to customers and internal users.
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>



      <MDBCard>
        <MDBCardHeader>Employment history</MDBCardHeader>
        <MDBCardBody>
<MDBRow>
          <MDBCol lg="6">
              <EmploymentCard
                location="Acuity Pricing, London" dates="June 2023 –> present" image="acuity.PNG"
                position="Lead software developer"
                badges={[
                  <MDBBadge pill color="green">C# .NET</MDBBadge>,
                  <MDBBadge pill color="info">Docker</MDBBadge>,
                  <MDBBadge pill color="orange">AWS</MDBBadge>,
                  <MDBBadge pill color="secondary">DynamoDB</MDBBadge>,
                  <MDBBadge pill color="secondary">Snowflake</MDBBadge>,
                  <MDBBadge pill color="secondary">MS SQL</MDBBadge>,
                  <MDBBadge pill color="red">Git</MDBBadge>,
                  <MDBBadge pill color="green">CircleCI</MDBBadge>,
                  <br />,]}
                employmentText={[
                  "•  Pivotal role in a large scale, mission critical .NET Framework to Dockerised .NET 6 greenfield refactoring project", <br />,
                  "•  Deeply involved in recruitment, design, infrastructure, knowledge share, coding/code review, containerisation and CI/CD pipeline", <br />,
                  "•  Continued to support developers, help with BAU tasks, tend to the heritage system and keep one eye on the future of the platform", <br />,
                  "•  Helped client and marketing teams with the technical aspects of the company website rebranding from RFS to Acuity Pricing", <br />,
                  
                ]}>
              </EmploymentCard>
            </MDBCol>
            
            <MDBCol lg="6">
              <EmploymentCard
                location="Ascential - Retail and Financial Services, London" dates="March 2021 –> March 2022 –> May 2023" image="Ascential.png"
                position="Software developer -> Lead software developer"
                badges={[
                  <MDBBadge pill color="green">C# .NET</MDBBadge>,
                  <MDBBadge pill color="green">Javascript</MDBBadge>,
                  <MDBBadge pill color="info">React</MDBBadge>,
                  <MDBBadge pill color="orange">AWS</MDBBadge>,
                  <MDBBadge pill color="secondary">DynamoDB</MDBBadge>,
                  <MDBBadge pill color="secondary">Snowflake</MDBBadge>,
                  <MDBBadge pill color="secondary">MS SQL</MDBBadge>,
                  <MDBBadge pill color="red">Git</MDBBadge>,
                  <br />,]}
                employmentText={[
                  "•  Responsible for significant knowledge transfer and legacy system analysis during transition period", <br />,
                  "•  Deconstructed and documented large components of the system with extreme cyclomatic complexity", <br />,
                  "•  Wrote test suite to capture existing functionality of monolithic components in order to begin refactoring", <br />,
                  "•  Involved with AWS infrastructure maintenance, security reviews and billing control", <br />,
                  
                ]}>
              </EmploymentCard>
            </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol lg="6">
              <EmploymentCard
                location="EDGE by Ascential, Reading" dates="May 2019 - Feb 2021" image="edge.jpg"
                position="Software developer - Data platform infrastructure"
                badges={[
                  <MDBBadge pill color="green">C# .NET</MDBBadge>,
                  <MDBBadge pill color="green">Javascript</MDBBadge>,
                  <MDBBadge pill color="info">React</MDBBadge>,
                  <MDBBadge pill color="orange">AWS</MDBBadge>,
                  <MDBBadge pill color="secondary">DynamoDB</MDBBadge>,
                  <MDBBadge pill color="secondary">Snowflake</MDBBadge>,
                  <MDBBadge pill color="secondary">MS SQL</MDBBadge>,
                  <MDBBadge pill color="red">Git</MDBBadge>,
                  <br />,]}
                employmentText={[
                  "•  Responsible for the data pipelines, ETL, bug fixes and performance tuning", <br />,
                  "•  Involved in feature requests and product design through Agile processes", <br />,
                  "•  Co-authoured a state machine that manages a Dynamo DB archival routine. Initial run deleted 18 billion rows", <br />,
                  "•  Made significant improvements to the product matching portal React app", <br />,
                  "•  Wrote several unit test projects to cover AWS Lambda activities and Snowflake data loading", <br />,
                  "•  Created Looker dashboards with a high standard of usability and data quality"
                ]}>
              </EmploymentCard>
            </MDBCol>

            <MDBCol lg="6">
              <EmploymentCard
                location="CSI LTD, Coleshill" dates="April 2018 – April 2019" image="csi.jpg"
                position="Software developer - Integration and automation"
                badges={[
                  <MDBBadge pill color="green">C# .NET</MDBBadge>,
                  <MDBBadge pill color="green">Powershell</MDBBadge>,
                  <MDBBadge pill color="info">MVC</MDBBadge>,
                  <MDBBadge pill color="blue">Azure</MDBBadge>,
                  <MDBBadge pill color="secondary">MS SQL</MDBBadge>,
                  <MDBBadge pill color="red">Git</MDBBadge>,
                  <br />,]}
                employmentText={[
                  "•  Contributed to the secure document upload portal project for Virgin Money Online", <br />,
                  "•  Extended database monitoring and cluster functionality of Solarwinds by using PowerShell", <br />,
                  "•  Pivotal role in assimilation and rationalisation of multiple monitoring systems", <br />,
                  "•  Helped with systems integration and handover after the acquisition and wrote extensive documentation"
                ]}>
              </EmploymentCard>
            </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol lg="6">
              <EmploymentCard
                location="niu solutions, Bagshot" dates="December 2014 - March 2018" image="niu.jpg"
                position="Software developer - Management and automation"
                badges={[
                  <MDBBadge pill color="green">C# .NET</MDBBadge>,
                  <MDBBadge pill color="green">Perl</MDBBadge>,
                  <MDBBadge pill color="info">MVC WEB API</MDBBadge>,
                  <MDBBadge pill color="info">Bootstrap</MDBBadge>,
                  <MDBBadge pill color="secondary">MS SQL</MDBBadge>,
                  <MDBBadge pill color="red">Git</MDBBadge>,
                  <MDBBadge pill color="dark">CA UIM Nimsoft</MDBBadge>,
                  <br />,]}
                employmentText={[
                  "•  Developed secure .NET MVC WEB API for Cyberark password vault to improve security and streamline RDP clients", <br />,
                  "•  Won the end of year ‘Golden Ticket’ award based on a colleague nomination process", <br />,
                  "•  Helped educate and inspire work experience and college placement students", <br />,
                  "•  Created integration with the CA UIM Nimsoft API saving months lost on repeated tasks", <br />,
                  "•  Contributed SSIS data imports and MS SQL database design to central integration projects", <br />,
                  "•  Helped to maintain various front-end projects using Bootstrap and Javascript", <br />,
                  "•  Wrote several custom Perl probes to enhance monitoring activities"
                ]}>
              </EmploymentCard>
            </MDBCol>

            <MDBCol lg="6">
              <EmploymentCard
                location="niu solutions, Bagshot" dates="Jan 2010 - November 2014" image="niu.jpg"
                position="Infrastructure and monitoring authourity"
                badges={[
                  <MDBBadge pill color="green">Classic ASP</MDBBadge>,
                  <MDBBadge pill color="green">Perl</MDBBadge>,
                  <MDBBadge pill color="green">Javascript</MDBBadge>,
                  <MDBBadge pill color="secondary">MS SQL</MDBBadge>,
                  <MDBBadge pill color="dark">CA UIM Nimsoft</MDBBadge>,

                  <br />,]}
                employmentText={[
                  "•  Built high-value, custom functionality into the helpdesk and monitoring systems", <br />,
                  "•  Designed and built an automated quality control system which saved hundreds of man hours", <br />,
                  "•  Instrumental in the introduction of CA UIM Nimsoft which eventually replaced IBM Director", <br />,
                  "•  Boosted the value of CA Nimsoft by scripting features that shortened delivery time", <br />,
                  "•  3rd line support tech providing assistance and mentoring for high-level technical challenges", <br />,
                  "•  Participated in problem ticket meetings, providing solutions for recurring technical problems"
                ]}>
              </EmploymentCard>
            </MDBCol>
            </MDBRow><MDBRow>
            <MDBCol lg="6">
              <EmploymentCard
                location="Pasporte Ltd, Bagshot" dates="July 2007 - Dec 2009" image="pasporte.gif"
                position="3rd line support tech"
                badges={[
                  <MDBBadge pill color="green">Classic ASP</MDBBadge>,
                  <MDBBadge pill color="green">Perl</MDBBadge>,
                  <MDBBadge pill color="primary">Microsoft MCSE+M</MDBBadge>,
                  <MDBBadge pill color="primary">Cisco CCNA</MDBBadge>,
                  <br />,]}
                employmentText={[
                  "•  Wrote the inaugural Perl script that opened up custom monitoring capabilities", <br />,
                  "•  Unpacked and studied the Classic ASP behind the the bespoke helpdesk system", <br />,
                  "•  Became technical owner for DNS and IBM Director monitoring", <br />,
                  "•  Became part of the technical approval team for the change request process", <br />,
                  "•	Appointed as quality control point-of-contact for the JD Sports project", <br />,
                  "•	Performed various data centre duties such as racking and quality control", <br />,
                  "•	Dealt with a wide range of customer support tickets and on-call duties"
                ]}>
              </EmploymentCard>
            </MDBCol>

            <MDBCol lg="6">
              <EmploymentCard
                location="Concorde Logistics Ltd, Southall" dates="Nov 2004 - March 2007" image="CL.jpg"
                position="3rd line support and infrastructure"
                badges={[
                  <MDBBadge pill color="green">VBScript</MDBBadge>,
                  <MDBBadge pill color="green">Perl</MDBBadge>,
                  <MDBBadge pill color="primary">Microsoft MCSE+M</MDBBadge>,
                  <MDBBadge pill color="primary">Cisco CCNA</MDBBadge>,
                  <br />,]}
                employmentText={[
                  "•	Developed management scripts and Squid management pages", <br />,
                  "•	Designed, project managed and carried out a migration from NT4 to 2003 for 350 users", <br />,
                  "•	Saved the company money by implementing Squid proxy server rather than ISA", <br />,
                  "•	Principally involved in a successful network congestion relief project", <br />,
                  "•	Instrumental role in extranet work utilising VPNs and separate AD domains", <br />,
                  "•	Responsible for health, maintenance and performance of the back-end servers and networks"
                ]}>
              </EmploymentCard>
            </MDBCol>
            </MDBRow><MDBRow>
            <MDBCol lg="6">
              <EmploymentCard
                location="Concorde Logistics Ltd, Southall" dates="Nov 2001 - Oct 2004" image="CL.jpg"
                position="2nd line support tech"
                badges={[
                  <MDBBadge pill color="primary">Microsoft MCSE+M</MDBBadge>,
                  <br />,]}

                employmentText={[
                  "•	Researched and project managed the installation of a new Avaya telephone system", <br />,
                  "•	Designed and built an SMS based alert system for warehouse call-out support", <br />,
                  "•	Provided internal desktop support and helpdesk services to 200 users in UK and Europe", <br />,
                  "•	Performed server maintenance and network troubleshooting", <br />,
                  "•	Set up IT equipment and connectivity requirements for new offices"
                ]}>
              </EmploymentCard>
            </MDBCol>
            </MDBRow>





        </MDBCardBody>
      </MDBCard>

      <MDBCard>
        <MDBCardHeader>Qualifications/legacy certifications</MDBCardHeader>
        <MDBCardBody>
          <MDBCardText>
            •	Windows 2003 MCSE+Messaging, CCNA (Cisco) and VCP3.5 (VMware) legacy certifications<br />
            •	MCSE/MCSA/CCNA certifications achieved through self-study in own time, VMware via employer<br />
            •	3 A-Levels in Computing, Maths and Physics – St Crispin’s School, Wokingham and Reading College<br />
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>

      <MDBCard>
        <MDBCardHeader>References</MDBCardHeader>
        <MDBCardBody>
          <MDBCardText>
            <MDBRow><MDBCol>Professional - Brandon Young</MDBCol><MDBCol>Personal - Lindsay Browning</MDBCol></MDBRow>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>

      <MDBCard>
        <MDBCardHeader>Interests</MDBCardHeader>
        <MDBCardBody>
          <MDBCardText>
            Mountain biking, baking, astronomy, music and birdwatching.
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>

    </MDBContainer>


  )
}

export default Cv;
