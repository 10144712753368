import React, { Component } from 'react';

import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBBadge,
  MDBIcon,
  MDBCardTitle
} from "mdbreact";


import './Cv.css';

const EmploymentCard = (props) => {

return(

    <MDBCard className="employmentCard">
        <MDBCardHeader className="employmentCardHeader">
            <MDBRow>

                <MDBCol size="8">
                    <MDBIcon fas icon="map-marker-alt"></MDBIcon> {props.location}<br />
                    <MDBIcon fas icon="calendar"></MDBIcon> {props.dates}<br />
                    <MDBIcon fas icon="user"></MDBIcon> {props.position}<br />
                    {props.badges}
                </MDBCol>

                <MDBCol className="employmentImage" size="4">
                    <img className="img-fluid" width="150px" src={`https://mattinglyuk.s3.eu-west-2.amazonaws.com/${props.image}`} alt={`${props.image}`}></img>
                </MDBCol>

            </MDBRow>
        </MDBCardHeader>

        <MDBCardBody>

            <MDBCardText className="employmentCardText">

                {props.employmentText}

            </MDBCardText>
        </MDBCardBody>
    </MDBCard>
)

}

export default EmploymentCard;