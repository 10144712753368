import logo from './logo.svg';
import './App.css';
import Cv from './Cv';

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

function App() {
  return (
    <Router>
      <Redirect from="/" to="/cv" />
      <Switch>
        <Route path="/cv">
          <Cv />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;